import {
	Business,
	Face,
	Group,
	Inventory2,
	LocationCity,
	Schedule,
	Security,
	SupervisedUserCircle,
	VerifiedUser,
	ViewColumn,
} from "@mui/icons-material";

export const LocationIcon = LocationCity;
export const BookingIcon = Schedule;
export const LockerIcon = ViewColumn;
export const UsersIcon = SupervisedUserCircle;
export const UserIcon = Face;
export const SuperuserIcon = VerifiedUser;
export const TenantIcon = Business;
export const CompartmentIcon = Inventory2;
export const GroupIcon = Group;
export const RoleIcon = Security;

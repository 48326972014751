import {Box, CardContent, CardHeader, FormHelperText, Typography} from "@mui/material";
import {EditForm, IntegerNumberFormat, NumberField, RadioGroup} from "@variocube/app-ui";
import React, {ReactNode, useEffect, useState} from "react";
import {BookingSettings} from "../../api";
import {useLocalization} from "../../i18n";

interface BookingSettingsEditFormProps {
	loading: boolean;
	bookingSettings?: BookingSettings;
	onSave: (bookingSettings: BookingSettings) => Promise<any>;
	title?: ReactNode;
}

export function BookingSettingsEditForm(props: BookingSettingsEditFormProps) {
	const {t} = useLocalization();
	const {loading, bookingSettings, onSave, title} = props;

	const [maxBookingsPerUser, setMaxBookingsPerUser] = useState<number | null>(null);
	const [temporary, setTemporary] = useState<boolean | null>(null);

	useEffect(() => {
		if (bookingSettings) {
			setMaxBookingsPerUser(bookingSettings.maxBookingsPerUser ?? null);
			setTemporary(bookingSettings.temporary ?? null);
		}
	}, [bookingSettings]);

	async function handleSave() {
		await onSave({
			maxBookingsPerUser: maxBookingsPerUser ?? undefined,
			temporary: temporary ?? undefined,
		});
	}

	return (
		<EditForm loading={loading} labels={t} onSave={handleSave}>
			{title && <CardHeader title={title} />}
			<CardContent>
				<NumberField
					label={t("settings.booking.maxBookingsPerUser.title")}
					numberFormat={IntegerNumberFormat}
					numberValue={maxBookingsPerUser}
					onChangeNumber={setMaxBookingsPerUser}
					fullWidth
				/>
				<FormHelperText>{t("settings.booking.maxBookingsPerUser.helperText")}</FormHelperText>
			</CardContent>
			<CardContent>
				<RadioGroup
					label={t("settings.booking.temporary.title")}
					options={["true", "false", "null"]}
					value={triStateBooleanToString(temporary)}
					onChange={value => setTemporary(triStateBooleanFromString(value))}
					renderLabel={option => (
						<Box my={1}>
							<Typography variant="body1">{t(`settings.booking.temporary.options.${option}`)}</Typography>
							<FormHelperText sx={{m: 0}}>
								{t(`settings.booking.temporary.helperTexts.${option}`)}
							</FormHelperText>
						</Box>
					)}
				/>
			</CardContent>
		</EditForm>
	);
}

function triStateBooleanToString(b: boolean | null) {
	if (b == null) {
		return "null";
	}
	if (b) {
		return "true";
	}
	return "false";
}

function triStateBooleanFromString(str: "true" | "false" | "null") {
	switch (str) {
		case "true":
			return true;
		case "false":
			return false;
		case "null":
			return null;
	}
}

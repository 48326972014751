import {Dialog, DialogContent, DialogTitle, Grid, Link, Typography} from "@mui/material";
import {Checkbox, useFlag} from "@variocube/app-ui";
import React, {Fragment} from "react";
import {Link as RouterLink} from "react-router-dom";
import {PERMISSIONS} from "../api";
import {useLocalization} from "../i18n";
import {useEmulatePermissions} from "../tenant";
import {useUser} from "../user";

export function FooterBar() {
	const {t} = useLocalization();
	const {superUser} = useUser();

	return (
		<Grid container spacing={2} sx={{px: 1, py: 0.5}} alignItems="center" displayPrint="none">
			<Grid item style={{flexGrow: 1}}>
				<Typography variant="overline" color="textSecondary">© SMALOX</Typography>
			</Grid>
			{location.hostname == "localhost" && (
				<Grid item>
					<Typography variant="overline">
						<EmulatePermissionsLink />
					</Typography>
				</Grid>
			)}
			{superUser && (
				<Grid item>
					<Typography variant="overline">
						<Link component={RouterLink} to="/tenants" color="secondary">
							{t("tenants.switch")}
						</Link>
					</Typography>
				</Grid>
			)}
			<Grid item>
				<Typography variant="overline">
					<Link href="/docs/api" color="secondary" target="_blank">{t("api-docs")}</Link>
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="overline">
					<Link href="https://www.stiwa.com/impressum" target="_blank" color="secondary">
						{t("imprint")}
					</Link>
				</Typography>
			</Grid>
		</Grid>
	);
}

function EmulatePermissionsLink() {
	const [open, setOpen, clearOpen] = useFlag(false);

	const [emulatedPermissions, setEmulatedPermissions] = useEmulatePermissions();

	return (
		<Fragment>
			<Link color="secondary" onClick={setOpen}>
				Emulate Permissions
			</Link>
			<Dialog open={open} onClose={clearOpen}>
				<DialogTitle>Emulate Permissions</DialogTitle>
				<DialogContent>
					{PERMISSIONS.map(permission => (
						<Checkbox
							key={permission}
							label={permission}
							checked={emulatedPermissions.includes(permission)}
							onChange={checked => {
								if (checked) {
									setEmulatedPermissions([...emulatedPermissions, permission]);
								}
								else {
									setEmulatedPermissions(emulatedPermissions.filter(p => p != permission));
								}
							}}
						/>
					))}
				</DialogContent>
			</Dialog>
		</Fragment>
	);
}

import {PersonAdd} from "@mui/icons-material";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ErrorAlert, useFlag} from "@variocube/app-ui";
import React, {Fragment, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {TenantUser} from "../../api";
import {useLocalization} from "../../i18n";
import {UserSelect} from "../user/UserSelect";

interface BookingUserAddButtonProps {
	onAddUsers: (users: TenantUser[]) => Promise<any>;
}

export function BookingUserAddButton({onAddUsers}: BookingUserAddButtonProps) {
	const {t} = useLocalization();
	const [addUserOpen, setAddUserOpen, clearAddUserOpen] = useFlag(false);
	const [users, setUsers] = useState<TenantUser[]>([]);

	const {loading, error, execute} = useAsyncCallback(onAddUsers);

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		await execute(users);
		clearAddUserOpen();
	}

	function handleCancel() {
		setUsers([]);
		clearAddUserOpen();
	}

	return (
		<Fragment>
			<Button onClick={setAddUserOpen} color="secondary" startIcon={<PersonAdd />}>
				{t("bookings.users.add")}
			</Button>

			<Dialog open={addUserOpen} onClose={handleCancel} fullWidth>
				<form onSubmit={handleSubmit}>
					<DialogTitle>
						{t("bookings.users.add")}
					</DialogTitle>
					<DialogContent>
						<Box pt={2}>
							<UserSelect value={users} onChange={setUsers} />
						</Box>
					</DialogContent>
					{error && (
						<DialogContent>
							<ErrorAlert error={error} />
						</DialogContent>
					)}
					<DialogActions>
						<Button type="submit" disabled={loading || users.length == 0}>
							{t("bookings.users.add")}
						</Button>
						<Button color="inherit" onClick={handleCancel}>
							{t("cancel")}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</Fragment>
	);
}
